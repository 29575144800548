<template>
	<div id="wrap">
		<top page="designer" @search="handleSearch" :showSearchButton="true" />
		<div class="mobile-wrap">
			<focus-pic category="设计师推荐焦点图" />
			<div 
				class="module-1" 
				v-for="category in categoryList"
				:key="category.id"
			>
				<div class="mainBox wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s">
					<div class="module-title">
						<h2>{{category.value}}</h2>
					</div>
				</div>
				<div class="mainBox">
					<div class="list-box wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s">
						<div class="list" v-for="item in category.designerList" :key="item.id">
							<div class="pic">
								<img :src="item.headImage.absolutePath" />
							</div>
							<div class="user">
								<h1>{{item.chineseName}}</h1>
								<h2>{{item.englishName}}</h2>
							</div>
							<div class="con">
								{{item.address}}
							</div>
						</div>
						<!--  -->
						<div class="clear"></div>
					</div>
				</div>
			</div>
			<!-- module-2 -->
			<div class="module-3">
				<div class="mainBox">
					
				</div>
			</div>
			<!-- module-3 -->
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue"
import bottom from "./components/bottom.vue"
import focusPic from "./components/focusPic.vue"
import side from "./components/side.vue"
import moment from 'moment'
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: '设计师'
	},
	filters: {
		filter_createTime(value) {
			return moment(value).format("YYYY.MM")
		}
	},
	data() {
		return {
			categoryList: [],
			paging: {
				pageIndex: 1,
				pageSize: 50,
				recordCount: 0,
				pageCount: 0
			},
			search: {
				chineseName: ''
			}
		}
	},
	components: {
		top,
		bottom,
		focusPic,
		side
	},
	methods: {
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL推荐设计师名单（中国）', // 分享标题
                        desc: '设计师均通过全球花艺名校严格考核认证',
                        link: response.data.url,
                        imgUrl: 'http://www.c-floral.net/images/share/logo-04.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleFindAllDesigner() {
			this.$api.commend.designer.findAll(this.search.chineseName)
			.then(response => {
				if(response.code === '0') {
					this.categoryList = response.data
					this.$nextTick(() => {
						new this.$wow.WOW().init()
					})
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleSearch(keywords) {
			this.search.chineseName = keywords;
			this.handleFindAllDesigner()
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleFindAllDesigner()
	}
}
</script>

<style src="../style/css/designer.css" scoped></style>
<style scoped>

</style>