<template>
    <div id="banner">
        <div class="banner" v-if="focusPicList.length > 0">
            <el-carousel :interval="5000" arrow="always" ref="slideCarousel">
                <el-carousel-item v-for="item in focusPicList" :key="item.id">
                    <div class="swiper-slide">
                        <a @click="handleClick(item)">
                            <img
                                :src="item.thumbnail.absolutePath"
                                style="width: 100%; display: block"
                            />
                        </a>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		category: {
            type: String,
            required: true,
        },
	},
    data() {
        return {
            focusPicList: [],
        };
    },
    methods: {
        handleFindFocusPic() {
            this.$api.focus.focusPic.findAll(this.category)
			.then((response) => {
                if (response.code === "0") {
                    this.focusPicList = response.data;
					this.$nextTick(() => {
						this.slideBanner()
					})
                } else {
                    this.$message.error(response.message);
                }
            });
        },
		handleClick(focus) {
			if(focus.mode === 'jump') {
				location.href = focus.href
			}
			else {
				this.$router.push({ path: `/banner/${focus.id}` })
			}
		},
        // 滑动切换
        slideBanner() {
			if(this.focusPicList.length == 0) {
				return
			}
            //选中的轮播图
            var box = document.querySelector(".el-carousel__container");
            var startPoint = 0;
            var stopPoint = 0;
            //重置坐标
            var resetPoint = function () {
                startPoint = 0;
                stopPoint = 0;
            };
            //手指按下
            box.addEventListener("touchstart", function (e) {
                //手指点击位置的X坐标
                startPoint = e.changedTouches[0].pageX;
            });
            //手指滑动
            box.addEventListener("touchmove", function (e) {
                //手指滑动后终点位置X的坐标
                stopPoint = e.changedTouches[0].pageX;
            });
            //当手指抬起的时候，判断图片滚动离左右的距离
            let that = this;
            box.addEventListener("touchend", function (e) {
                if (stopPoint == 0 || startPoint - stopPoint == 0) {
                    resetPoint();
                    return;
                }
                if (startPoint - stopPoint > 0) {
                    resetPoint();
                    that.$refs.slideCarousel.next();
                    return;
                }
                if (startPoint - stopPoint < 0) {
                    resetPoint();
                    that.$refs.slideCarousel.prev();
                    return;
                }
            });
        },
    },
    mounted() {
        this.handleFindFocusPic();
    },
};
</script>